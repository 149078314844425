<template>
  <div v-if="question">
    <sqr-input-text
      class="mb-3"
      label="Placeholder"
      :value="question.placeholder"
      @change="propSet('placeholder', $event)"
    />
    <div class="mb-3 grid grid-cols-2 gap-y-3 gap-x-6">
      <sqr-input-number
        label="Min"
        :value="question.minValue"
        @change="propSet('minValue', $event)"
      />
      <sqr-input-number
        label="Max"
        :value="question.maxValue"
        @change="propSet('maxValue', $event)"
      />
    </div>
  </div>
</template>

<script>
import base from '../base';
import SqrInputNumber from '@/sqrd-ui/SqrInputNumber';
import SqrInputText from '@/sqrd-ui/SqrInputText';
export default {
  name: 'TextEdit',
  mixins: [base],
  components: { SqrInputNumber, SqrInputText },
};
</script>
